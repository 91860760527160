<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Inventaris Perorangan</h5>
            </template>
            <p>Info! Hanya dapat <span class="text-danger">diedit</span> maksimal pada hari yang sama. setalah itu
                dinyatakan sudah fix.</p>
            <div class="search-wrapper d-flex align-items-center justify-content-between">
                <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
                    <div class="d-flex search-input-wrapper">
                        <b-form-input class="ml-2 mr-2" v-model="filter" name="filterTable" id="filterInput" type="search"
                            placeholder="Type to search"></b-form-input>
                        <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy"
                            @click="onFilter(filter)">
                            <i class="fa fa-search"></i>
                        </b-button>
                        <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy"
                            @click="onReset()">Reset</b-button>
                    </div>
                </b-form-group>
            </div>

            <div class="d-flex justify-content-end py-2">
                <b-button class="mb-3" variant="pgiBtn" @click="$bvModal.show('m-create')">
                    <i class="fa fa-plus"></i> Tambah Data Inventaris
                </b-button>
            </div>

            <b-table bordered hover show-empty :busy="isBusy" :items="inventaris" :fields="fields" responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data jenis barang.
                </template>
                <template v-slot:cell(kelengkapan)="row">
                    <ul class="list-none-wrapper" v-if="row.item.kelengkapan.length > 0">
                        <li v-for="kelengkapan in row.item.kelengkapan" v-bind:key="kelengkapan.id">
                            <span>
                                <i class="fa fa-check" v-if="(kelengkapan.isActive == true)"></i>
                                <i class="fa fa-times" v-if="(kelengkapan.isActive == false)"></i>
                                {{ kelengkapan.name }}
                            </span>
                        </li>
                    </ul>
                    <span v-else>-</span>
                </template>
                <template v-slot:cell(status)="row">
                    <b-badge variant="success" v-if="row.item.status == true">Suksess</b-badge>
                    <b-badge variant="warning" v-else>Menunggu Pembayaran</b-badge>
                </template>
                <template v-slot:cell(action)="row">
                    <b-row>
                        <!-- <b-col cols=1>
                            <b-link :to="`${$route.path}/${row.item.noBarang}/detail`">
                                <b-button size="sm" class="table-button view" v-b-tooltip.hover title="Detail"
                                    name="jenisBarangViewBtn">
                                    <i class="fa fa-info"></i>
                                </b-button>
                            </b-link>
                        </b-col> -->
                        <b-col>
                            <b-button size="sm" variant="warning" v-b-tooltip.hover title="Return Barang"
                                name="stokViewBtn">
                                <i class="text-white fa fa-repeat"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>

            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <span class="ml-2">{{ totalViewText }}</span>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>

            <!-- MODAL CREATE -->
            <b-modal id="m-create" size="lg" title="Formulir tambah data inventaris" no-close-on-backdrop no-close-on-esc
                hide-footer>
                {{ divisi }}

                <b-row>
                    <b-col>
                        <b-form-group>
                            <label>Gudang</label>
                            <multiselect v-model="form.gudang" :options="gudangOpt" :internal-search="true"
                                :allow-empty="false" deselect-label="" select-label="" label="name" track-by="kode"
                                placeholder="-- Pilih Gudang --" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label>Divisi</label>
                            <multiselect v-model="divisi" :options="divisiOpt" :internal-search="true" :allow-empty="false"
                                deselect-label="" select-label="" label="nm_Divisi" track-by="DivisiID"
                                placeholder="-- Pilih Divisi --" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <label>Nama Karyawan</label>
                            <multiselect v-model="form.pemakai" :options="penerimaOpt" :internal-search="true"
                                :allow-empty="false" deselect-label="" select-label="" label="nama_karyawan" track-by="nik"
                                placeholder="-- Pilih Karyawan --" :disabled="divisi == null ? true : false" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="8">
                        <b-form-group>
                            <label>Jenis Barang</label>
                            <multiselect v-model="idJenis" :options="jenisBarang"
                                :disabled="form.gudang == null ? true : false" label="nama_barang" track-by="id"
                                class="header-select mr-3" placeholder="Masukan Kode / Nama Barang" :searchable="true"
                                :internal-search="false" :loading="isLoading" :clear-on-select="false"
                                :show-no-results="false" @search-change="searchBarang" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Stok Barang">
                            <b-form-input id="stok" v-model="stok" type="text" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="qty" :disabled="tipeKode == 1 ? true : false">
                            <b-form-input id="qty" v-model="form.jumlah_stok" type="number" min="0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Satuan">
                            <b-form-input id="satuan" v-model="form.satuan" type="text" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Imei">
                            <b-form-input id="imei" v-model="form.imei" type="text"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Detail">
                            <b-form-input id="detail" v-model="form.detail_barang" type="text"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                {{ form }}
                <div class="mt-4 text-right">
                    <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
                        Simpan
                    </b-button>
                </div>
            </b-modal>

            <ModalConfirm id="m-confirm-create" title="Konfirmasi Tambah Data" :isSubmitting="isSubmitting"
                desc="Apakah anda yakin untuk menambah Data inventaris ini?" @submit="onSubmit" />

        </b-card>
    </div>
</template>

<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export default {
    name: 'laporan-inventaris',
    components: {
        Vue,
    },
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isLoading: false,
            isSubmitting: false,
            input: {
                show: 10
            },
            fields: [
                { key: "no", label: "No", sortable: true },
                { key: "tanggal", label: "Tanggal" },
                { key: "pemegang", label: "Pemegang" },
                { key: "jabatan", label: "Jabatan" },
                { key: "noBarang", label: "No Barang" },
                { key: "namaBarang", label: "Nama Barang" },
                { key: "imei", label: "Imei" },
                { key: "detailbarang", label: "Detail Barang" },
                { key: "action", label: "Aksi" },
            ],
            json_fields: {
                "No": "no",
                "Tanggal": "tanggal",
                "Pemegang": "pemegang",
                "No Barang": "noBarang",
                "Nama Barang": "namaBarang",
                "Imei": "imei",
                "Detail Barang": "detailBarang"
            },

            form: {
                nomer_barang: null,
                id_tipe: null,
                detail_barang: null,
                imei: null,
                gudang: null,
                pemakai: null,
                jumlah_stok: null,
                satuan: null,
                total_asset: null,
            },
            divisi: null,
            idJenis: null,
            tipeKode: null,
            stok: null,

            jenisBarang: [],
            gudangOpt: [
                { kode: "0999", name: "Gudang Pusat" },
            ],
            divisiOpt: [],
            penerimaOpt: [
            ],

            filter: null,

            inventaris: [],

            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            query: "",
        }
    },
    methods: {

        //----------------- Fungsi Inisialisasi -----------------

        async searchBarang(query) {
            this.jenisBarang = []

            if (query.length > 2) {
                this.isLoading = true;
                // make API request to load websites
                let request = await this.$axios.get('api/internal-memo/memo/stock-barang-v3?kode_cabang=' + this.form.gudang.kode + '&search=' + query)
                //let data = await request.json()
                if (request.data.data.length != 0) {
                    for (var data of request.data.data) {
                        data.nama_barang = data.nomer_barang + ' - ' + data.barang_tipe.tipe
                    }
                    this.jenisBarang = request.data.data
                }
                this.isLoading = false;
            }
        },

        //----------------- Fungsi Inisialisasi -----------------

        init() {
            this.initStatus = 0

            let getInventaris = this.$axios.get("api/transaksi/stok-inventaris/paginate").then(response => {

                let datas = response.data.data

                let i = 1
                for (var data of datas.data) {
                    this.inventaris.push({
                        no: i,
                        tanggal: data.tanggal,
                        pemegang: (data.karyawan != null) ? data.karyawan.nama_karyawan : '-',
                        jabatan: (data.karyawan != null) ? data.karyawan.jabatan.nama_jabatan : '-',
                        noBarang: data.nomer_barang,
                        namaBarang: (data.barang_tipe == null) ? '-' : data.barang_tipe.tipe,
                        imei: data.imei,
                        detailbarang: data.detail_barang,
                    })
                    i++
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
            })

            let getDivisi = this.$axios.get("api/admin/devisi/all").then(response => {
                for (var data of response.data.data) {
                    this.divisiOpt.push(data)
                }
            })

            Promise.all([getInventaris, getDivisi]).then(() => {
                this.initStatus = 1
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        //-----------------  Fungsi Ambil Data Jenis Barang ----------------- 

        getInventaris() {

            this.toggleBusy()
            this.inventaris = []

            // if (this.filter.no_invoice != null) this.query = this.query + "&no_invoice=" + this.filter.no_invoice
            // if (this.filter.id_supplier != null) this.query = this.query + "&id_supplier=" + this.filter.id_supplier.id
            // if (this.filter.dateFrom != null) this.query = this.query + "&from=" + this.filter.dateFrom + "&to=" + this.filter.dateTo

            this.$axios.get("api/transaksi/stok-inventaris/paginate" + this.query).then(response => {

                let datas = response.data.data

                let i = datas.from
                for (var data of datas.data) {
                    this.inventaris.push({
                        no: i,
                        tanggal: data.tanggal,
                        pemegang: (data.karyawan != null) ? data.karyawan.nama_karyawan : '-',
                        jabatan: (data.karyawan != null) ? data.karyawan.jabatan.nama_jabatan : '-',
                        noBarang: data.nomer_barang,
                        namaBarang: (data.barang_tipe == null) ? '-' : data.barang_tipe.tipe,
                        imei: data.imei,
                        detailbarang: data.detail_barang,
                    })
                    i++
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.isLoading = false

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.isLoading = false
                })
        },


        prepare(modalToShow) {

            if (this.form.gudang == null) {
                return this.$helper.toastErr(this, "Mohon pilih gudang!")
            }

            if (this.form.pemakai == null) {
                return this.$helper.toastErr(this, "Mohon pilih pemakai!")
            }

            // if (this.form.id_tipe == null) {
            //     return this.$helper.toastErr(this, "Mohon pilih barang!")
            // }

            if (this.form.imei == null) {
                return this.$helper.toastErr(this, "Mohon isi imei!")
            }

            if (this.form.jumlah_stok == null) {
                return this.$helper.toastErr(this, "Mohon isi Jumlah Stok!")
            }

            if (this.form.detail_barang == null || this.form.detail_barang == "") {
                return this.$helper.toastErr(this, "Mohon isi Detail Barang!")
            }
            this.$bvModal.show(modalToShow)

        },


        onSubmit() {
            this.form.gudang = this.form.gudang.kode
            this.form.pemakai = this.form.pemakai.nik

            this.$axios.post('api/transaksi/stok-inventaris/create', this.form).then((response) => {
                this.form = {
                    nomer_barang: null,
                    id_tipe: null,
                    detail_barang: null,
                    imei: null,
                    gudang: null,
                    pemakai: null,
                    jumlah_stok: null,
                    satuan: null,
                    total_asset: null,
                },
                    this.divisi = null,
                    this.idJenis = null,
                    this.tipeKode = null,
                    this.stok = null

                this.$bvModal.hide("m-create")
                this.$bvModal.hide("m-confirm-create")
                this.$helper.toastSucc(this, "Data berhasil ditambahkan!")

                this.getInventaris()
            })
        },
        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onPageChange() {
            if (this.isLoading == false) {
                this.page = this.currentPage
                this.query = "?page=" + this.page
                this.getInventaris()
            }
        },

        onReset() {
            this.filter = ""
            this.toggleBusy()
            this.getInventaris()
        },


        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },

        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },

    },

    created() {
        this.init()
    },

    watch: {
        // "form.gudang": function(newVal){
        //     this.form.id_tipe = null
        // },
        "idJenis": function (newVal) {
            if (newVal.jumlah_stok < 0) {
                this.stok = 0
            } else {
                this.stok = newVal.jumlah_stok
            }
            this.form.nomer_barang = newVal.nomer_barang
            this.form.id_tipe = newVal.id_tipe
            this.form.satuan = newVal.satuan
            this.form.imei = newVal.imei
            this.form.total_asset = newVal.total_asset
            this.form.detail_barang = newVal.detail_barang

            this.tipeKode = newVal.barang_tipe.tipe_kode
            if (newVal.barang_tipe.tipe_kode == 1) {
                this.form.jumlah_stok = 1
            }
        },
        "divisi": function (newVal) {
            this.form.pemakai = null
            this.penerimaOpt = []
            this.$axios.get("api/admin/karyawan/all?id_divisi=" + newVal.DivisiID).then(response => {

                for (var data of response.data.data) {
                    this.penerimaOpt.push(data)
                }
            })
        }
    }
}
</script>
